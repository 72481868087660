import React from "react";
import styled from "styled-components";

const StyledNotification = styled.p`
  padding: 10px 25px;
  border: 2px solid rgba(249, 249, 102, 0.9);
  background: #fff9b5;
  color: #222;
  border-radius: 3px;

  font-size: 20px;
`;

function Notification(props: any) {
  const { children } = props;
  return (
    <StyledNotification className="notification">{children}</StyledNotification>
  );
}

export default Notification;
