const key = "selected-screen-effect";

const defaultVal = "opacity";

export default function () {
  return localStorage.getItem(key) || defaultVal;
}

export function setSelectedScreenEffect(lang) {
  localStorage.setItem(key, lang);
}
