import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import cn from "classnames";

import Header from "../../../components/Header";
import { FlashcardContext, UserContext } from "../../../components/Layout";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { cleanAndSplit } from "../../videos/VideoDetails";
import { API_ROOT } from "../../../constants";
import Video from "./CatalogFlashcardVideo";
import { navigate } from "gatsby";
import getSelectedLang from "../../../util/getSelectedLang";

const Container = styled.div`
  h1 {
    a {
      color: var(--link);
    }
  }

  p {
    text-align: center;
    max-width: 500px;
    font-size: 22px;
    margin: 24px auto 0 auto;
  }

  .relative-container {
    position: relative;
    margin: 0 auto;
    width: min-content;

    button#prev-word {
      z-index: 100000;
      position: absolute;
      top: 50%;
      left: -120px;
      width: max-content;
    }
    button#next-word {
      z-index: 10000;
      position: absolute;
      top: 50%;
      right: -120px;
      width: max-content;
    }
    @media screen and (max-width: 800px) {
      width: 100%;
      margin-top: 50px;

      button#prev-word {
        top: -45px;
        left: 10px;
      }
      button#next-word {
        top: -45px;
        right: 10px;
      }
    }
  }

  #notes-container {
    max-width: 600px;
    margin: 0 auto;
    label {
      display: flex;
      flex-direction: column;
      font-size: 18px;

      textarea {
        background: var(--bg);
        border: 1px solid var(--elevation-bg);
        color: var(--color);
        padding: 10px;
        resize: none;
        font-family: inherit;
        box-sizing: border-box;
        font-size: 22px;
      }
    }
  }

  span {
    color: var(--color);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.isWord {
      color: var(--link);
    }
  }

  @media screen and (max-width: 800px) {
    h1 {
      display: none;
    }
  }
`;

function CatalogFlashcardDetails(props) {
  const { uuid } = props;

  const user = useContext(UserContext);

  const { flashcards, setFlashcards } = useContext(FlashcardContext);
  const [flashcard, setFlashcard] = useState(null);

  const [isExtendedReplay, setIsExtendedReplay] = useState(false);
  const [isEntireReplay, setIsEntireReplay] = useState(false);
  const [flashcardNotes, setFlashcardNotes] = useState("");
  const [modifierKey, setModifierKey] = useState("");
  const [isNotesSelected, setIsNotesSelected] = useState("");

  const selectedLang = getSelectedLang();

  useEffect(() => {
    if (user.uuid && flashcard?.uuid !== uuid) {
      axios
        .get(API_ROOT + "catalog-flashcards/" + user.uuid + "/" + uuid)
        .then(({ data }) => {
          setFlashcard(data.flashcard);
          setFlashcardNotes(data.flashcard.notes);
        })
        .catch((err) => console.error(err));
    }
  }, [user, uuid]);

  useEffect(() => {
    if (user.uuid && !flashcards.length) {
      axios
        .get(
          API_ROOT + "catalog-flashcards/" + user.uuid + "?lang=" + selectedLang
        )
        .then(({ data }) => {
          setFlashcards(data.flashcards);
        })
        .catch((err) => console.error(err));
    }
  }, [user, selectedLang]);

  useEffect(() => {
    function handleBeforeUnload(e) {
      axios
        .post(API_ROOT + "catalog-flashcards/save/" + user.uuid + "/" + uuid, {
          notes: flashcardNotes,
        })
        .then(({ data }) => console.log(data))
        .catch((err) => console.log(err.message));
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [flashcardNotes, uuid]);

  if (!flashcard) {
    return <LoadingSpinner />;
  }

  const clickHandler = (word) => (e) => {
    e.preventDefault();
    setTimeout(() => {
      setModifierKey(null);
    }, 100);

    if (modifierKey === "v") {
      // * Will cover more languages
      if (flashcard.lang === "de") {
        window.open(
          `https://conjugator.reverso.net/conjugation-german-verb-${word}.html`,

          "top=100,left=200,menubar=0,resizable=1,width=900,height=800"
        );
      }
      window.open(
        `https://www.wordreference.com/conj/${flashcard.lang}verbs.aspx?v=${word}`,
        "top=100,left=200,menubar=0,resizable=1,width=900,height=800"
      );
      // * Do flashcard API call
    }
    window.open(
      `https://www.wordreference.com/${flashcard.lang}en/${word}`,
      "WordReference",
      "top=100,left=200,menubar=0,resizable=1,width=900,height=800"
    );
  };

  const splitOriginalText = cleanAndSplit(flashcard.originalText);

  const flashcardIndex = flashcards.findIndex((card) => card.uuid === uuid);

  return (
    <Container>
      <Header>
        {flashcard.wordOrPhrase.map((w) => (
          <span onClick={clickHandler(w.normalizedWord)}>{w.word} </span>
        ))}
      </Header>

      <div className="relative-container">
        {flashcardIndex > 0 && (
          <button
            onClick={async (e) => {
              e.preventDefault();

              await axios.post(
                API_ROOT + "catalog-flashcards/save/" + user.uuid + "/" + uuid,
                {
                  notes: flashcardNotes,
                }
              );
              navigate(
                "/app/catalog/flashcards/" +
                  flashcards[flashcardIndex - 1]?.uuid
              );
            }}
            id="prev-word"
          >
            {"<---"}
          </button>
        )}
        <Video
          key={flashcard.uuid}
          setModifierKey={setModifierKey}
          isNotesSelected={isNotesSelected}
          url={flashcard.url}
          start={parseFloat(flashcard.start)}
          end={flashcard.end}
          startAboveText={flashcard.startAboveText}
          endBelowText={flashcard.endBelowText}
          isExtendedReplay={isExtendedReplay}
          setIsExtendedReplay={setIsExtendedReplay}
          isEntireReplay={isEntireReplay}
          setIsEntireReplay={setIsEntireReplay}
          videoUuid={flashcard.videoUuid}
          wordOrPhrase={flashcard.wordOrPhrase}
          originalText={flashcard.originalText}
          lang={flashcard.lang}
        />
        {flashcardIndex < flashcards.length - 1 && (
          <button
            id="next-word"
            onClick={async (e) => {
              e.preventDefault();

              await axios.post(
                API_ROOT + "catalog-flashcards/save/" + user.uuid + "/" + uuid,
                {
                  notes: flashcardNotes,
                }
              );

              navigate(
                "/app/catalog/flashcards/" +
                  flashcards[flashcardIndex + 1]?.uuid
              );
            }}
          >
            {"--->"}
          </button>
        )}
      </div>
      <p>
        {isExtendedReplay &&
          flashcard.aboveText &&
          flashcard.aboveText.split(" ").map((word, idx) => {
            const normalizedWord = word.replace(/(\(|\)|,|\.|\:|\!)/g, "");
            return (
              <>
                <span
                  key={word + idx}
                  onClick={clickHandler(normalizedWord)}
                  // ! This is extended an therefore should not contain the phrase
                  // className={cn({
                  //   isWord: normalizedWord === flashcard.wordOrPhrase,
                  // })}
                >
                  {word}
                </span>
                {idx + 1 !== splitOriginalText.length && " "}
              </>
            );
          })}
        {splitOriginalText.map((word, idx) => {
          const normalizedWord = word.replace(/(\(|\)|,|\.|\:|\!)/g, "");
          const isWord = flashcard.wordOrPhrase.find(
            (word) => word.normalizedWord === normalizedWord
          );

          return (
            <>
              <span
                key={word + idx}
                onClick={clickHandler(normalizedWord)}
                className={cn({
                  isWord,
                })}
              >
                {word}
              </span>
              {idx + 1 !== splitOriginalText.length && " "}
            </>
          );
        })}{" "}
        {isExtendedReplay &&
          flashcard.belowText &&
          flashcard.belowText.split(" ").map((word, idx) => {
            const normalizedWord = word.replace(/(\(|\)|,|\.|\:|\!)/g, "");
            return (
              <>
                <span
                  key={word + idx}
                  onClick={clickHandler(normalizedWord)}
                  // ! This is extended an therefore should not contain the phrase
                  // className={cn({
                  //   isWord: normalizedWord === flashcard.wordOrPhrase,
                  // })}
                >
                  {word}
                </span>
                {idx + 1 !== splitOriginalText.length && " "}
              </>
            );
          })}
      </p>
      <TextArea
        isNotesSelected={isNotesSelected}
        setIsNotesSelected={setIsNotesSelected}
        setFlashcardNotes={setFlashcardNotes}
        flashcardNotes={flashcardNotes}
        uuid={uuid}
        user={user}
      />
    </Container>
  );
}

class TextArea extends React.Component {
  componentWillUnmount() {
    axios.post(
      API_ROOT +
        "catalog-flashcards/save/" +
        this.props.user.uuid +
        "/" +
        this.props.uuid,
      {
        notes: this.props.flashcardNotes,
      }
    );
  }
  render() {
    return (
      <div id="notes-container">
        <label
          htmlFor="notes"
          onClick={() => this.props.setIsNotesSelected(true)}
          onBlur={() => this.props.setIsNotesSelected(false)}
          onFocus={() => this.props.setIsNotesSelected(true)}
        >
          <strong>Notes:</strong>
          <textarea
            placeholder="Notes are saved automatically when you navigate away."
            value={this.props.flashcardNotes}
            onChange={(e) => this.props.setFlashcardNotes(e.target.value)}
            name="notes"
            id="notes"
            cols="30"
            rows="10"
          ></textarea>
        </label>
      </div>
    );
  }
}

export default CatalogFlashcardDetails;
