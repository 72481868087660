import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_ROOT } from "../../constants";
import queryString from "query-string";
import styled from "styled-components";
import Header from "../../components/Header";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import { navigate } from "gatsby";

const Container = styled.div`
  max-width: 650px;
  margin: 0 auto;

  a {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

function NewsDetails(props) {
  const { outlet } = props;
  const { search } = props.location;
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState("");
  const qs = queryString.parse(search);

  useEffect(() => {
    const qs = queryString.parse(search);
    axios
      .get(API_ROOT + `articles/read/${outlet}?uuid=` + qs.uuid)
      .then(({ data }) => {
        setArticle({ ...data.article });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [search]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "c") {
        setKey(event.key);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "c") {
        setKey("");
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Container>
        <Header>{wrapTextInDictionLinks(article?.title, key, setKey)}</Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a target="_blank" href={article?.href}>
            Article Link
          </a>

          <div>
            <Menu>
              <MenuButton className="menu-btn">
                Actions <span aria-hidden>▾</span>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onSelect={() =>
                    navigate(`/app/news/translate/${outlet}?uuid=` + qs.uuid)
                  }
                >
                  Translate Article
                </MenuItem>
                <MenuItem onSelect={() => console.log("FEEDBACK")}>
                  File Bug or Feedback
                </MenuItem>
                <MenuItem onSelect={() => console.log("SHORTCUTS")}>
                  View Keyboard Shortcuts
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
        <Body
          content={article?.body}
          modifierKey={key}
          setModifierKey={setKey}
        />
      </Container>
    </>
  );
}

const Body = (props) => {
  const { content, modifierKey: key, setModifierKey } = props;
  const [renderedContent, setRenderedContent] = useState([]);

  useEffect(() => {
    const newRenderedContent = [];
    content.forEach((el, idx) => {
      if (el.tagName === "P") {
        newRenderedContent.push(
          <p key={idx}>
            {wrapTextInDictionLinks(el.text, key, setModifierKey)}
          </p>
        );
      } else if (el.tagName === "BLOCKQUOTE") {
        newRenderedContent.push(
          <blockquote key={idx}>
            {wrapTextInDictionLinks(el.text, key, setModifierKey)}
          </blockquote>
        );
      } else if (el.tagName === "H2") {
        newRenderedContent.push(
          <h2 key={idx}>
            {wrapTextInDictionLinks(el.text, key, setModifierKey)}
          </h2>
        );
      }
    });

    setRenderedContent(newRenderedContent);
  }, [content, key]);

  return <div>{renderedContent}</div>;
};

function wrapTextInDictionLinks(text, key, setModifierKey) {
  return text
    .replace(/\n/, " ")
    .split(" ")
    .map((word, idx) => (
      <span key={word + idx}>
        <a
          onClick={(e) => {
            e.preventDefault();
            setTimeout(() => {
              setModifierKey(null);
            }, 100);

            const lang = "fr";
            const normalizedWord = word.replace(/(«|»|\,|\.|\:)/g, "");
            if (key === "c") {
              if (lang === "de") {
                window.open(
                  `https://conjugator.reverso.net/conjugation-german-verb-${normalizedWord}.html`
                );
              }
              return window.open(
                `https://www.wordreference.com/conj/${lang}verbs.aspx?v=${normalizedWord}`
              );
            }
            window.open(
              `https://www.wordreference.com/${lang}en/${normalizedWord}`,
              "WordReference",
              "top=100,right=600,menubar=0,resizable=1,width=900,height=800"
            );
          }}
        >
          {word}
        </a>{" "}
      </span>
    ));
}

export default NewsDetails;
