import React from "react";
import Modal from "./Modal";

export default function FeedbackModal(props) {
  const { isModalOpen, setIsModalOpen } = props;
  return (
    <Modal
      height={400}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div>
        <h2>File Bug or Feedback</h2>
        <p style={{ maxWidth: "300px", margin: "0 auto" }}>
          Please tell us what is wrong or what you would like changed.
        </p>
      </div>
      <label htmlFor="">
        <textarea
          style={{
            width: "100%",
            resize: "none",
            fontFamily: "inherit",
            boxSizing: "border-box",
            marginBottom: "10px",
            padding: "10px",
          }}
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      </label>
      <div className="button-container">
        <button
          onClick={(e) => {
            setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          onClick={(e) => {
            // * API call to delete
          }}
          style={{ background: "blue" }}
        >
          Send
        </button>
      </div>
    </Modal>
  );
}
