import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import axios from "axios";
import { API_ROOT } from "../../constants";
import { UserContext } from "../../components/Layout";
import LoadingSpinner from "../../components/LoadingSpinner";
import removeAccents from "remove-accents";

const Container = styled.div`
  max-width: 800px;
  margin: 30px auto;

  p {
    margin: 0 auto;
    width: max-content;
  }

  input {
    display: block;
    margin: 25px auto;
    font-size: 1.35rem;
    font-family: inherit;
    border: 1px solid var(--elevation-bg);
  }

  button {
    display: block;
    width: max-content;
    margin: 0 auto;
    background: var(--bg);
  }

  button.nav-button {
    background: var(--elevation-bg);
  }

  .text-btn-container {
    margin-top: 10px;
  }
`;

function TypingDetails(props) {
  const { uuid } = props;
  const user = useContext(UserContext);
  const [type, setType] = useState(null);
  const [text, setText] = useState("");
  const [idx, setIdx] = useState(0);
  const paragraphRef = useRef(null);
  const inputRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (user.uuid) {
      try {
        axios
          .get(API_ROOT + "typings/" + user.uuid + "/" + uuid)
          .then(({ data }) => {
            console.log("🚀 ~ .then ~ data:", data);
            setType(data.typing);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user]);

  useEffect(() => {
    if (type) {
      setWidth(paragraphRef?.current?.getBoundingClientRect().width + 2);
    }
  }, [idx, type]);

  if (!type) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header>{type.title}</Header>
      <p ref={paragraphRef}>{parseSubtitles(type, idx, text)}</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setIdx(idx + 1);
          setText("");
        }}
      >
        {width && (
          <input
            spellCheck={false}
            type="text"
            onChange={(e) => setText(e.target.value)}
            value={text}
            ref={inputRef}
            style={{
              width,
            }}
          />
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            style={{
              pointerEvents: !!idx ? "auto" : "none",
              opacity: !!idx ? "1" : 0,
            }}
            onClick={(e) => {
              e.preventDefault();
              setIdx(idx - 1);
            }}
            className="nav-button"
            type="button"
          >
            {"<- "}Prev
          </button>
          <button className="nav-button" type="submit">
            Next{" ->"}
          </button>
        </div>
      </form>
      <br />
      <br />
      <br />
      <br />
      {type.subtitles
        // .filter((obj, innerIdx) => innerIdx >= idx)
        .map((obj, innerIdx) => {
          if (innerIdx < idx) {
            return null;
          }
          return (
            <div className="text-btn-container">
              <button
                style={{
                  color: idx === innerIdx ? "var(--link)" : "var(--color)",
                }}
                onClick={() => {
                  setIdx(innerIdx);
                  window.scrollTo(0, 0);
                  inputRef.current.focus();
                }}
              >
                {obj.text}
              </button>
            </div>
          );
        })}
    </Container>
  );
}

function parseSubtitles(type, idx, text) {
  const subtitleArr = type.subtitles[idx].text.trim().split(/\s+/);
  const textArr = text.split(/\s/);

  return subtitleArr.map((word, innerIdx) => (
    <span
      key={word + innerIdx}
      style={{
        color:
          removeAccents(word).toLowerCase() ===
          (textArr[innerIdx] ?? "").toLowerCase()
            ? "var(--link)"
            : "var(--color)",
      }}
    >
      {word}{" "}
    </span>
  ));
}

export default TypingDetails;
