import React from "react";
import { Router } from "@reach/router";

import VideosList from "../app/videos/VideosList";
import AddVideo from "../app/videos/AddVideo";
import VideoDetails from "../app/videos/VideoDetails";
import TypingDetails from "../app/typings/TypingDetails";
import Flashcards from "../app/flashcards";
import FlashcardDetails from "../app/flashcards/FlashcardDetails";
import NewsList from "../app/news/NewsList";
import NewsDetails from "../app/news/NewsDetails";
import NewsOutlets from "../app/news/NewsOutlets";
import NewsTranslate from "../app/news/NewsTranslate";
import Catalog from "../app/catalog";
import CatalogList from "../app/catalog/CatalogVideoList";
import AddChannel from "../app/catalog/AddChannel";
import CatalogVideo from "../app/catalog/CatalogVideo";
import CatalogChannelList from "../app/catalog/CatalogChannelList";
import CatalogFlashcards from "../app/catalog/flashcards";
import CatalogFlashcardDetails from "../app/catalog/flashcards/CatalogFlashcardDetails";
import BookLanguages from "../app/books/Languages";
import BookList from "../app/books/BookList";
import BookDetails from "../app/books/BookDetails";

function App() {
  return (
    <Router basepath="/app">
      <NewsDetails path="/news/read/article/:outlet" />
      <NewsOutlets path="news" />
      <NewsList path="/news/read/:outlet" />
      <NewsTranslate path="/news/translate/:outlet" />

      <Flashcards path="/videos/flashcards" />
      <FlashcardDetails path="/videos/flashcards/:uuid" />

      <TypingDetails path="/typing/:uuid" />

      <VideoDetails path="/video/:uuid" />
      <VideosList path="/videos/:page" />
      <AddVideo path="/videos/add-video" />

      <Catalog path="/catalog" />
      <CatalogVideo path="/catalog/video/:uuid" />
      <CatalogList path="/catalog/:channelId/:page" />
      <AddChannel path="/catalog/add-channel" />
      <CatalogChannelList path="/catalog/:lang" />
      {/* CatalogFlashcards */}
      <CatalogFlashcards path="/catalog/flashcards" />
      <CatalogFlashcardDetails path="/catalog/flashcards/:uuid" />

      <BookLanguages path="/books/languages" />
      <BookDetails path="/books/:lang/:uuid" />
      <BookList path="/books/:lang" />
    </Router>
  );
}

export default App;
