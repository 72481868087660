import { langMap } from "../app/catalog/CatalogChannelList";

export default function copyText(arg) {
  const { wordOrPhrase, originalText, lang } = arg;

  navigator.clipboard.writeText(
    `In one sentence, would you explain the phrase "${wordOrPhrase
      .map((obj) => obj.word)
      .join(" ")}" in the following passage: ${originalText.replaceAll(
      /\n/g,
      " "
    )}.`
  );
}
