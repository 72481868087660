import React from "react";
import Header from "../../components/Header";
import { Link } from "gatsby";
import styled from "styled-components";

const Container = styled.div`
  max-width: 650px;
  margin: 0 auto;

  li a {
    font-size: 18px;
    color: var(--color);
  }
`;

function NewsOutlets(props) {
  return (
    <Container>
      <Header>News Outlets</Header>
      <h2>French</h2>
      <ul>
        <li>
          <Link to="/app/news/read/le-figaro">Le Figaro</Link>
        </li>
        <li>
          <Link to="/app/news/read/le-parisien">Le Parisien</Link>
        </li>
        <li>
          <Link to="/app/news/read/france24">France 24</Link>
        </li>
      </ul>
      {/* <h2>Spanish</h2>
      <ul>
        <li>
          <Link to="/app/news/read/le-figaro">El Mundo</Link>
        </li>
        <li>
          <Link to="/app/news/read/le-parisien">El Periodico</Link>
        </li>
        <li>
          <Link to="/app/news/read/france24">El Pais</Link>
        </li>
        <li>
          <Link to="/app/news/read/france24">El Diario</Link>
        </li>
        <li>
          <Link to="/app/news/read/france24">BBC Mudno</Link>
        </li>
      </ul>
      <h2>German</h2>
      <ul>
        <li>
          <Link to="/app/news/read/le-figaro">Spiegel</Link>
        </li>
      </ul> */}
    </Container>
  );
}

export default NewsOutlets;
