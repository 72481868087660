import axios from "axios";
import { navigate } from "gatsby";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../../components/Layout";
import { API_ROOT } from "../../constants";
import getSelectedLang from "../../util/getSelectedLang";
import Notification from "../../components/Notification";
import { Snackbar } from "@mui/material";

const StyledVideoMain = styled.main`
  box-sizing: border-box;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    label {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      font-size: 16px;
      max-width: 74vw;

      padding: 8px 10px;
      border: 1px solid #222;
      border-radius: 3px;
    }

    #public-video-checkbox {
      padding-top: 20px;
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;

      input {
        height: 22px;
        width: 22px;
      }
    }

    button {
      margin-top: 10px;
      border-radius: 3px;
      padding: 8px 10px;
      width: 80px;
      display: flex;
      justify-content: center;
      background: #222;
      font-size: 18px;
      color: #fff;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`;

const initialState = {
  url: "",
  lang: "",
  isLangMissing: false,
  listOfSubtitleOptions: null,
  isPublic: false,
};

const initialSnackbarState = {
  isOpen: false,
  message: "",
};

const AddVideo = (props) => {
  const user = useContext(UserContext);
  const inputRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [formState, setFormState] = useState(initialState);

  const [snackbar, setSnackbar] = useState(initialSnackbarState);

  const handleClose = () => {
    setSnackbar(initialSnackbarState);
  };

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }

  useEffect(() => {
    inputRef?.current.focus();
  }, []);

  return (
    <StyledVideoMain>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsDisabled(true);
          const { url, lang, isLangMissing, isPublic } = formState;
          if (!url) {
            return;
          }
          let videoId = "";
          if (url.match(/ube.com\/watch/)) {
            const search = url.match(/\?.*/);

            const qs = queryString.parse(search[0]);
            videoId = qs.v;
          } else if (url.match(/(\.be\/[\d\w]*[^\?])/)) {
            const match = url.match(/(\.be\/[\d\w]*[^\?])/);
            videoId = match[0].slice(4);
          }

          try {
            // * Is Admin adding public
            if (isPublic) {
              const resp = await axios.post(API_ROOT + "videos/public", {
                userUuid: user.uuid,
                url: `https://www.youtube.com/watch?v=${videoId}`,
                isLangMissing,
                lang,
                email: user.email,
              });

              if (resp.data.isLangMissing) {
                setIsDisabled(false);
                return setFormState({
                  ...formState,
                  isLangMissing: true,
                  listOfSubtitleOptions: resp.data.listOfSubtitleOptions,
                });
              }

              // * There needs to be a chance to hit another endpoint to get the list of languages

              return navigate("/app/video/" + resp.data.video.uuid);
            }

            const resp = await axios.post(API_ROOT + "videos", {
              userUuid: user.uuid,
              url: `https://www.youtube.com/watch?v=${videoId}`,
              isLangMissing,
              email: user.email,
              lang,
            });

            if (resp.data.isLangMissing) {
              setIsDisabled(false);
              return setFormState({
                ...formState,
                isLangMissing: true,
                listOfSubtitleOptions: resp.data.listOfSubtitleOptions,
              });
            }

            if (resp.data.isError) {
              return setSnackbar({
                isOpen: true,
                message: resp.data.errorMessage,
              });
            }

            navigate("/app/video/" + resp.data.video.uuid);
          } catch (err) {
            console.log("🚀 ~ file: AddVideo.jsx:89 ~ onSubmit={ ~ err:", err);
          }
        }}
      >
        <label htmlFor="url">Add a YouTube URL:</label>
        <input
          type="text"
          name="url"
          id="url"
          onChange={handleChange}
          ref={inputRef}
        />
        {formState.isLangMissing && formState.listOfSubtitleOptions && (
          <>
            <label htmlFor="subtitle-track">Select Subtitle Track</label>

            <select
              name="lang"
              id="subtitle-track"
              value={formState.lang}
              onChange={handleChange}
            >
              <option disabled value="">
                Please Select Track
              </option>
              {formState.listOfSubtitleOptions.map((captionObj) => {
                return (
                  <option key={captionObj.lang} value={captionObj.lang}>
                    {captionObj.simpleText}
                  </option>
                );
              })}
            </select>
          </>
        )}
        {user.email === "swcfischer@gmail.com" && (
          <label id="public-video-checkbox" htmlFor="">
            Public Video:
            <input
              type="checkbox"
              name="public-video-checkbox"
              id="public-video-checkbox"
              value={formState.isPublic}
              onChange={function handleToggle(e) {
                setFormState({
                  ...formState,
                  isPublic: e.target.value,
                });
              }}
            />
          </label>
        )}

        <button disabled={isDisabled} type="submit">
          Add
        </button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />
    </StyledVideoMain>
  );
};

export default AddVideo;

function handleSubtitleLang(val = "") {
  if (val.includes("-")) {
    const [initialVal] = val.split("-");

    return initialVal;
  }
  return val;
}
