import React from "react";
import Modal from "./Modal";

export default function FlashcardModal(props) {
  const {
    isModalOpen,
    setIsModalOpen,
    flashcardDefinition,
    setFlashcardDefinition,
  } = props;
  return (
    <Modal
      height={375}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <p>{flashcardDefinition}</p>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          onClick={(e) => {
            // * API call to delete
            setIsModalOpen("");
            setFlashcardDefinition("");
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

function styleParagraph(text) {
  const val = [];

  text.split(" ").forEach((word) => {
    const initialChar = word[0];
    const lastChar = word[word.length - 1];
    const penultimateChar = word[word.length - 2];

    if (initialChar === '"' && lastChar === '"') {
      return val.push(`<strong>${word}</strong>`);
    } else if (
      (initialChar === '"' && lastChar === '"') ||
      (initialChar === '"' && penultimateChar === '"')
    ) {
      return val.push(`<strong>${word}`);
    } else if (initialChar === '"') {
      return val.push(`<strong>${word}`);
    } else if (lastChar === '"') {
      return val.push(`${word}</strong>`);
    }
    return val.push(word);
  });
  return val.join(" ");
}
