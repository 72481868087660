import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import ReactPlayer from "react-player/youtube";
import styled from "styled-components";
import isMobileJS from "ismobilejs";

const Container = styled.div`
  margin: 0 auto;

  .video-container {
    margin: 10px auto;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    width: fit-content;

    .remove-pointer-events {
      transition: backdrop-filter 0.3s ease;
      backdrop-filter: grayscale(
          ${(props) => (props.isPlaying ? "0%" : "100%")}
        )
        blur(${(props) => (props.isPlaying ? "0px" : "5px")});
    }

    .remove-pointer-events,
    .mask {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 10000;
    }
  }

  .button-container {
    max-width: 800px;
    margin: 0 auto;
    display: flex;

    button {
      width: max-content;
      margin-right: 8px;
    }

    .button-like {
      background: var(--elevation-bg);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 8px;
      color: #fff;
      font-size: 18px;
      border-radius: 3px;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const initialConfig = {
  url: "",
  playing: false,
  controls: false,
  light: false,
  playbackRate: 1,
  volume: 1,
  isSeeking: false,
  loaded: 0,
  duration: 0,
};

// * Names need to be switched
const widthToHeightRatio = 1.7777777777777777;
const heightToWidth = 450 / 800;

const initialDimensions = {
  width: 800,
  height: 450,
};

function Video(props) {
  const {
    start,
    end,
    startAboveText,
    endBelowText,
    isNotesSelected,
    isExtendedReplay,
    setIsExtendedReplay,
    isEntireReplay,
    setIsEntireReplay,
    setModifierKey,
    videoUuid,
  } = props;

  const playerRef = useRef(null);

  const [isFirstPlay, setIsFirstPlay] = useState(true);
  const [dimensions, setDimensions] = useState(initialDimensions);
  const isMobile = isMobileJS(window.navigator);

  const [config, setConfig] = useState({
    ...initialConfig,
    played: start,
    playing: false,
  });

  // useEffect(() => {
  //   playerRef.current.seekTo(isExtendedReplay ? startAboveText : start);
  // }, [playerRef, isExtendedReplay, startAboveText, start]);

  function handleResize() {
    const windowWidth = isMobile.any ? window.screen.width : window.innerWidth;
    const windowHeight = isMobile.any
      ? window.screen.height
      : window.innerHeight;

    let height = windowHeight - 200;
    let width = height * widthToHeightRatio;

    if (windowWidth > windowHeight && width < windowWidth) {
      height = windowHeight - 200;
      width = height * widthToHeightRatio;
    } else {
      width = windowWidth - 60;
      width = windowWidth - 20;
      height = width * heightToWidth;
    }
    if (initialDimensions.width > width) {
      setDimensions({
        height,
        width,
      });
    } else {
      setDimensions(initialDimensions);
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === " " && !isNotesSelected) {
        e.preventDefault();
      }
      if (e.key === "c" && !isNotesSelected) {
        e.preventDefault();
        setModifierKey("c");
      }
    }

    function handleKeyUp(e) {
      if (e.key === " " && !isNotesSelected) {
        if (isFirstPlay) {
          playerRef.current.seekTo(start);
          setConfig({
            ...config,
            played: start,
            playing: !config.playing,
          });
          return setIsFirstPlay(false);
        }

        setConfig({
          ...config,
          playing: !config.playing,
        });
      }

      if (e.key === "c" && !isNotesSelected) {
        e.preventDefault();
        setModifierKey("");
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [config, isFirstPlay, playerRef, isNotesSelected]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleProgress(state) {
    if (
      isEntireReplay
        ? false
        : state.played > parseFloat(isExtendedReplay ? endBelowText : end)
    ) {
      setIsFirstPlay(true);
      return setConfig({
        ...config,
        ...state,
        playing: false,
      });
    }

    if (!config.isSeeking && config.playing) {
      setConfig({
        ...config,
        ...state,
      });
    }
  }

  return (
    <Container isPlaying={config.playing}>
      <div className="video-container">
        <ReactPlayer
          ref={playerRef}
          url={props.url}
          playing={config.playing}
          controls={config.controls}
          volume={config.volume}
          onProgress={handleProgress}
          width={dimensions.width}
          height={dimensions.height}
        />
        <div className="remove-pointer-events" />
        <div
          className="mask"
          onClick={(e) => {
            e.preventDefault();
            if (isFirstPlay) {
              playerRef.current.seekTo(
                isExtendedReplay ? startAboveText : start
              );
              setConfig({
                ...config,
                played: start,
                playing: !config.playing,
              });
              setIsFirstPlay(false);
            } else {
              setConfig({
                ...config,
                playing: !config.playing,
              });
            }
          }}
        ></div>
      </div>
      <div className="button-container">
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsExtendedReplay(false);
            setIsEntireReplay(false);
            playerRef.current.seekTo(start);
            setConfig({
              ...config,
              played: start,
              playing: true,
            });
          }}
        >
          Replay
        </button>
        {startAboveText && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsEntireReplay(false);
              setIsExtendedReplay(true);
              playerRef.current.seekTo(startAboveText);
              setConfig({
                ...config,
                played: startAboveText,
                playing: true,
              });
            }}
          >
            Extended
          </button>
        )}
        <button
          style={{
            background: isEntireReplay ? "black" : "var(--elevation-bg)",
          }}
          onClick={(e) => {
            e.preventDefault();
            if (isEntireReplay) {
              return setIsEntireReplay(false);
            }
            setIsEntireReplay(true);
            if (!config.playing) {
              playerRef.current.seekTo(
                config.played
                  ? config.played
                  : isExtendedReplay
                  ? startAboveText
                  : start
              );
            }
            setConfig({
              ...config,
              playing: true,
            });
          }}
        >
          Endless
        </button>
        <Link
          className="button-like"
          to={`/app/video/${videoUuid}?start=${config.played}`}
        >
          Video
        </Link>
      </div>
    </Container>
  );
}

export default Video;
