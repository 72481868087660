import React from "react";
import Header from "../../components/Header";
import styled from "styled-components";
import { Link } from "gatsby";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;

  a {
    font-size: 22px;
    color: inherit;
  }
`;

function BookList(props) {
  return (
    <Container>
      <Header>Book List</Header>
      <Link to="/app/books/fr/123">Le Blanc et le Noir</Link>
    </Container>
  );
}

export default BookList;
