import { useMemo } from "react";

const key = "selected-lang";

export default function () {
  return localStorage.getItem(key) || "all";
}

export function setSelectedLang(lang) {
  localStorage.setItem(key, lang);
}
