import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import axios from "axios";
import { API_ROOT } from "../../constants";
import { Link } from "gatsby";
import LoadingSpinner from "../../components/LoadingSpinner";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;

  li {
    a {
      font-size: 20px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const outletMap = {
  "le-figaro": "Le Figaro",
  "le-parisien": "Le Parisien",
};

function NewsList(props) {
  const { outlet } = props;
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios
      .get(API_ROOT + "articles/" + outlet)
      .then(({ data }) => {
        setArticles(data.links);
      })
      .catch((err) => console.error(err.message));
  }, []);

  if (!articles?.length) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header>{outletMap[outlet]}</Header>
      <ul>
        {articles.map((link) => (
          <li key={link.href}>
            <Link
              to={`/app/news/read/article/${outlet}?` + "uuid=" + link.uuid}
            >
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  );
}

export default NewsList;
