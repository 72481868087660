import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";

import { API_ROOT } from "../../constants";
import { UserContext } from "../../components/Layout";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Link, navigate } from "gatsby";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";
import Pagination from "../../components/Pagination";
import getSelectedLang from "../../util/getSelectedLang";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  min-height: 130vh;

  button#add-video {
    margin-right: 15px;
  }

  button {
    display: flex;
    align-items: center;
    width: max-content;
    margin-bottom: 15px;
  }

  .video-list-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 47%);
      gap: 20px;
    }
    a {
      text-decoration: none;
      .video-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        p {
          color: var(--color);
          height: 70px;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0;
          margin: 0;
          font-weight: bold;
          font-size: 18px;
          &:hover {
            text-decoration: underline;
          }
        }

        figure {
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
          width: 100%;
          height: 0;
          padding-top: calc(9 / 16 * 100%);
          position: relative;
          overflow: hidden;
          background: #222;

          &:hover + p {
            text-decoration: underline;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 3px;
            cursor: pointer;
            border: 1px solid #222;
            animation: fadeIn 0.5s ease;
          }
          /* Lang */
          span {
            position: absolute;
            top: -3px;
            right: 0px;
            background: #000;
            border: 1px solid #222;
            border-top: 4px solid #222;
            padding: 5px 8px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 2px;
          }
        }
      }
    }
  }
`;

function VideosList(props) {
  const { page } = props;
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useContext(UserContext);
  const selectedLang = getSelectedLang();

  useEffect(() => {
    if (user.uuid) {
      try {
        axios
          .get(
            API_ROOT +
              "videos/" +
              user.uuid +
              "?page=" +
              page +
              "&lang=" +
              selectedLang
          )
          .then(({ data }) => {
            if (data.videos.length < 1) {
              navigate("/app/videos/add-video");
            }
            setIsLoading(false);
            setVideos(data.videos);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  }, [user, page]);

  if (isLoading && !videos.length) {
    // Add loading spinner component
    return <LoadingSpinner />;
  }
  return (
    <Container>
      <Helmet>
        <title>Videos</title>
      </Helmet>
      <Header>Videos</Header>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <button
          id="add-video"
          onClick={(e) => {
            e.preventDefault();
            navigate("/app/videos/add-video");
          }}
        >
          Add Video
        </button>
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/app/videos/flashcards");
          }}
        >
          Flashcards
        </button> */}
      </div>
      <Pagination
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        userUuid={user.uuid}
        curPage={page}
        hasMore={videos.length === 7}
        path="videos/"
      />
      <div className="video-list-container">
        {videos.map((vid, idx) => {
          if (idx === 6) {
            return null;
          }
          return (
            <Link key={vid.uuid} alt={vid.title} to={`/app/video/${vid.uuid}`}>
              <div className="video-card">
                <figure>
                  <img src={vid.thumbnail} alt={vid.title} />
                  <span>{vid.lang}</span>
                </figure>
                <p className="title">{vid.title}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </Container>
  );
}

export default VideosList;
