import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API_ROOT } from "../constants";
import { navigate } from "gatsby";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  button {
    transition: opacity 0.3s ease;
  }
`;

function Pagination(props) {
  const { setIsLoading, isLoading, hasMore, path } = props;
  const curPage = Number(props.curPage);
  const isPrev = curPage - 1 > 0;

  return (
    <Container>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (isLoading) {
            return;
          }
          navigate("/app/" + path + (curPage - 1));
          setIsLoading(true);
        }}
        style={{
          opacity: isPrev ? 1 : 0,
          pointerEvents: isPrev ? "auto" : "none",
        }}
      >
        {"<-"} Prev
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (isLoading) {
            return;
          }
          navigate("/app/" + path + (curPage + 1));
          setIsLoading(true);
        }}
        style={{
          opacity: hasMore ? 1 : 0,
          pointerEvents: hasMore ? "auto" : "none",
        }}
      >
        Next {"->"}
      </button>
    </Container>
  );
}

export default Pagination;
