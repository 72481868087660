import React from "react";
import Modal from "./Modal";
import axios from "axios";
import { API_ROOT } from "../../constants";
import { navigate } from "gatsby";

export default function DeleteModal(props) {
  const { isModalOpen, setIsModalOpen, videoUuid, userUuid } = props;

  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div>
        <h2>Delete Video</h2>
        <p>
          Deleting this video also deletes its falshcards. Are you sure you want
          to delete this video?
        </p>
      </div>
      <div className="button-container">
        <button
          onClick={(e) => {
            setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          onClick={(e) => {
            // * API call to delete
            axios
              .delete(API_ROOT + "videos/" + videoUuid + "/" + userUuid)
              .then((res) => {
                if (!res.data.isError) {
                  navigate("/app/videos/1");
                }
              })
              .catch((err) => console.log(err));
          }}
          style={{ background: "#c70000" }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
}
