import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player/youtube";
import styled, { css } from "styled-components";
import { UserContext } from "../../components/Layout";
import { API_ROOT, isDev } from "../../constants";

import isMobileJS from "ismobilejs";
import queryString from "query-string";

import LoadingSpinner from "../../components/LoadingSpinner";
import { Tooltip } from "@reach/tooltip";
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";

import DeleteModal from "../../components/Modals/DeleteModal";
import FeedbackModal from "../../components/Modals/FeedbackModal";
import ShortcutModal from "../../components/Modals/ShortcutModal";
import FlashcardModal from "../../components/Modals/FlashcardModal";

import "@reach/menu-button/styles.css";
import "@reach/tooltip/styles.css";
import classNames from "classnames";
import { navigate } from "gatsby";
import getSelectedScreenEffect from "../../util/getSelectedScreenEffect";
import getSelectedLockedVideo, {
  setSelectedLockedVideo,
} from "../../util/getSelectedLockedVideo";
import copyText from "../../util/copyTextForChatGPT";
import { Snackbar, Alert } from "@mui/material";

// * Names need to be switched
const widthToHeightRatio = 1.7777777777777777;
const heightToWidth = 450 / 800;

const initialConfig = {
  url: "",
  playing: false,
  controls: false,
  light: false,
  playbackRate: 1,
  volume: 1,
  seeking: false,
  played: 0,
  loaded: 0,
  duration: 0,
};

const initialDimensions = {
  width: 800,
  height: 450,
};

const initialSnackbarState = {
  isOpen: false,
  message: "",
};

const Video = (props) => {
  // * video ID
  const { uuid } = props;
  const { start } = queryString.parse(props.location.search);

  const playerRef = useRef(null);

  const user = useContext(UserContext);

  const [video, setVideo] = useState(null);
  const [startingPoint, setStartingPoint] = useState(null);
  const [dimensions, setDimensions] = useState(initialDimensions);
  const [config, setConfig] = useState(initialConfig);
  const [modifierKey, setModifierKey] = useState(null);
  const [flashcardWords, setFlashcardWords] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [areSubtitlesHidden, setAreSubtitlesHidden] = useState(false);
  const [areSubtitlesHiddenOnPause, setAreSubtitlesHiddenOnPause] =
    useState(false);
  const [selectedScreenEffect, _] = useState(getSelectedScreenEffect());

  const [selectedWords, setSelectedWords] = useState([]);
  const [snackbar, setSnackbar] = useState(initialSnackbarState);
  const [flashcardDefinition, setFlashcardDefinition] = useState("");

  const isMobile = isMobileJS(window.navigator);

  const handleClose = () => {
    setSnackbar(initialSnackbarState);
  };

  function handleProgress(state) {
    if (!config.isSeeking && config.playing) {
      setConfig({
        ...config,
        ...state,
      });
    }
  }

  function handleResize() {
    const windowWidth = isMobile.any ? window.screen.width : window.innerWidth;
    const windowHeight = isMobile.any
      ? window.screen.height
      : window.innerHeight;

    let height = windowHeight - 200;
    let width = height * widthToHeightRatio;

    if (windowWidth > windowHeight && width < windowWidth) {
      height = windowHeight - 200;
      width = height * widthToHeightRatio;
    } else {
      width = windowWidth - 60;
      width = windowWidth - 20;
      height = width * heightToWidth;
    }

    setDimensions({
      height,
      width,
    });
  }

  useEffect(() => {
    if (user.uuid) {
      try {
        axios
          .get(API_ROOT + "videos/" + user.uuid + "/" + uuid)
          .then(({ data }) => {
            setVideo(data.video);
            handleResize();

            const newStartingPoint = start
              ? start
              : localStorage.getItem(data.video.uuid) ?? 0.0;

            setStartingPoint(parseFloat(newStartingPoint));

            setConfig({
              ...config,
              url: data.video.url,
            });

            setFlashcardWords(data.video.words);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === "ArrowLeft" && !isModalOpen) {
        const ratio = (config.playedSeconds - 3) / config.playedSeconds;
        playerRef.current.seekTo(ratio * config.played);
        setConfig({
          ...config,
          playedSeconds: config.playedSeconds - 3,
          played: ratio * config.played,
        });
      }

      if (e.key === "ArrowRight" && !isModalOpen) {
        const ratio = (config.playedSeconds + 3) / config.playedSeconds;
        playerRef.current.seekTo(ratio * config.played);
        setConfig({
          ...config,
          played: ratio * config.played,
          playedSeconds: config.playedSeconds + 3,
        });
      }
      if (e.key === " " && !isModalOpen) {
        e.preventDefault();
      }

      if (["w", "v"].includes(e.key)) {
        setModifierKey(e.key);
      }
    }
    function handleKeyUp(e) {
      if (e.key === "Escape" && isModalOpen) {
        return setIsModalOpen("");
      }

      if (e.key === " " && !isModalOpen) {
        if (startingPoint) {
          playerRef.current.seekTo(startingPoint);
          setConfig({
            ...config,
            played: startingPoint,
            playing: !config.playing,
          });
          return setStartingPoint(null);
        }

        setConfig({
          ...config,
          playing: !config.playing,
        });
      }

      if (["w", "v"].includes(e.key)) {
        setModifierKey(null);
        setSelectedWords([]);
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      config.played && localStorage.setItem(uuid, config.played);
    };
  }, [config, uuid, startingPoint, playerRef, isModalOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!video) {
    return <LoadingSpinner />;
  }

  function isShowSubtitles() {
    if (areSubtitlesHidden) {
      return false;
    }
    if (areSubtitlesHiddenOnPause) {
      return !config.playing;
    }
    return config && video && !areSubtitlesHidden;
  }

  return (
    <Container
      playing={config.playing}
      controls={config.controls}
      screenEffect={selectedScreenEffect}
    >
      <Helmet>
        <title>{video.title}</title>
      </Helmet>
      {/* <Link to="/app/add-video">AddVideo</Link> */}
      <div className="responsive-container">
        <div className="player-container">
          <ReactPlayer
            key={config.controls}
            ref={playerRef}
            url={config.url}
            playing={config.playing}
            controls={config.controls}
            volume={config.volume}
            width={dimensions.width}
            height={dimensions.height}
            onProgress={handleProgress}
            onPause={() => {
              setConfig({
                ...config,
                playing: false,
              });
            }}
            onPlay={() => {
              if (startingPoint) {
                playerRef.current.seekTo(startingPoint);
                setConfig({
                  ...config,
                  played: startingPoint,
                  playing: true,
                });

                return setStartingPoint(null);
              }
              setConfig({
                ...config,
                playing: true,
              });
            }}
          />
        </div>
        {!config.controls && (
          <div
            className="click-handler"
            title={config.playing ? "" : "Click to play the video"}
            onClick={(e) => {
              e.preventDefault();
              if (startingPoint) {
                playerRef.current.seekTo(startingPoint);
                setConfig({
                  ...config,
                  played: startingPoint,
                  playing: !config.playing,
                });
                return setStartingPoint(null);
              }
              setConfig({
                ...config,
                playing: !config.playing,
              });
            }}
          ></div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: dimensions.width,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip
            label="Auto-generated or human-written, subtitles can be wrong from time to time."
            style={{
              background: "#222",
              color: "white",
              border: "none",
              borderRadius: "4px",
              padding: "0.5em 1em",
              fontSize: "18px",
            }}
          >
            <button
              style={{
                maxWidth: "min-content",
                marginRight: "12px",
                borderRadius: "50%",
                position: "relative",
                lineHeight: "10px",
                height: 22,
                width: 22,
              }}
            >
              i
            </button>
          </Tooltip>

          <div className="range-container">
            <label htmlFor="volume">
              Volume
              <input
                onChange={(e) => {
                  e.preventDefault();
                  setConfig({
                    ...config,
                    volume: e.target.value * 0.01,
                  });

                  e.target.blur();
                }}
                value={config.volume * 100}
                name="volume"
                id="volume"
                type="range"
              />
            </label>
            <label htmlFor="seek">
              Seek
              <input
                min={0}
                max={0.9999}
                step="any"
                name="seek"
                id="seek"
                type="range"
                value={config.played}
                onChange={(e) => {
                  e.preventDefault();
                  setConfig({
                    ...config,
                    played: parseFloat(e.target.value),
                  });
                }}
                onMouseDown={(e) => {
                  if (isMobile.any) {
                    return;
                  }
                  setConfig({
                    ...config,
                    isSeeking: true,
                  });
                }}
                onMouseUp={(e) => {
                  if (isMobile.any) {
                    return;
                  }
                  setConfig({
                    ...config,
                    isSeeking: false,
                  });
                  playerRef.current.seekTo(parseFloat(e.target.value));
                  e.target.blur();
                }}
                onTouchStart={(e) => {
                  if (isMobile.any) {
                    setConfig({
                      ...config,
                      isSeeking: true,
                    });
                  }
                }}
                onTouchEnd={(e) => {
                  if (isMobile.any) {
                    setConfig({
                      ...config,
                      isSeeking: false,
                    });
                    playerRef.current.seekTo(parseFloat(e.target.value));
                    e.target.blur();
                  }
                }}
              />
            </label>
          </div>
        </div>
        <div>
          <Menu>
            <MenuButton className="menu-btn">
              Actions <span aria-hidden>▾</span>
            </MenuButton>
            <MenuList>
              <MenuItem onSelect={() => setIsModalOpen("DELETE")}>
                Delete Video
              </MenuItem>
              {/* <MenuItem onSelect={() => setIsModalOpen("FEEDBACK")}>
                File Bug or Feedback
              </MenuItem> */}
              <MenuItem
                onSelect={(e) => {
                  document.activeElement.blur();
                  setConfig({
                    ...config,
                    controls: !config.controls,
                  });
                }}
              >
                {config.controls ? "Lock Player" : "Unlock Player"}
              </MenuItem>
              <MenuItem
                onSelect={() => {
                  document.activeElement.blur();
                  setAreSubtitlesHidden(!areSubtitlesHidden);
                  setAreSubtitlesHiddenOnPause(false);
                }}
              >
                {areSubtitlesHidden ? "Show Subtitles" : "Hide Subtitles"}
              </MenuItem>
              <MenuItem
                onSelect={() => {
                  document.activeElement.blur();
                  setAreSubtitlesHidden(false);
                  setAreSubtitlesHiddenOnPause(!areSubtitlesHiddenOnPause);
                }}
              >
                {areSubtitlesHiddenOnPause
                  ? "Turn off Show Subtitles on Pause"
                  : "Show Subtitles on Pause"}
              </MenuItem>
              <MenuItem
                onSelect={() => {
                  navigate("/app/typing/" + video.uuid);
                }}
              >
                Type Out Subtitles
              </MenuItem>

              <MenuItem
                onSelect={() => {
                  // * String below appears to have carriage returns "\n"
                  const text = video.subtitles
                    .map((subObj) => subObj.text)
                    .join(" ");
                  navigator.clipboard.writeText(text);
                }}
              >
                Copy Subtitles to Clipboard
              </MenuItem>
              <MenuLink as="a" target="_blank" href={video.url}>
                Go to YouTube Video
              </MenuLink>
              <MenuItem onSelect={() => setIsModalOpen("SHORTCUTS")}>
                View Keyboard Shortcuts
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      {isMobile.any && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px auto",
            maxWidth: dimensions.width,
          }}
        >
          <button
            onClick={() => {
              document.activeElement.blur();
              const ratio = (config.playedSeconds - 3) / config.playedSeconds;
              playerRef.current.seekTo(ratio * config.played);
              setConfig({
                ...config,
                playedSeconds: config.playedSeconds - 3,
                played: ratio * config.played,
              });
            }}
          >
            Back
          </button>
          <button
            onClick={() => {
              document.activeElement.blur();
              const ratio = (config.playedSeconds + 3) / config.playedSeconds;
              playerRef.current.seekTo(ratio * config.played);
              setConfig({
                ...config,
                played: ratio * config.played,
                playedSeconds: config.playedSeconds + 3,
              });
            }}
          >
            Forward
          </button>
        </div>
      )}
      {isShowSubtitles() && (
        <div className="subtitle" style={{ maxWidth: dimensions.width }}>
          <SubtitleLines
            config={config}
            setConfig={setConfig}
            video={video}
            modifierKey={modifierKey}
            setModifierKey={setModifierKey}
            userUuid={user.uuid}
            videoUuid={uuid}
            flashcardWords={flashcardWords}
            setFlashcardWords={setFlashcardWords}
            url={config.url}
            setSelectedWords={setSelectedWords}
            selectedWords={selectedWords}
            isMobile={isMobile}
            setSnackbar={setSnackbar}
            flashcardDefinition={flashcardDefinition}
            setFlashcardDefinition={setFlashcardDefinition}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      )}
      <DeleteModal
        isModalOpen={isModalOpen === "DELETE"}
        setIsModalOpen={setIsModalOpen}
        videoUuid={uuid}
        userUuid={user.uuid}
      />
      <FeedbackModal
        isModalOpen={isModalOpen === "FEEDBACK"}
        setIsModalOpen={setIsModalOpen}
        videoUuid={uuid}
        userUuid={user.uuid}
      />
      <ShortcutModal
        isModalOpen={isModalOpen === "SHORTCUTS"}
        setIsModalOpen={setIsModalOpen}
        videoUuid={uuid}
        userUuid={user.uuid}
      />
      <FlashcardModal
        isModalOpen={isModalOpen === "FLASHCARD"}
        flashcardDefinition={flashcardDefinition}
        setIsModalOpen={setIsModalOpen}
        videoUuid={uuid}
        userUuid={user.uuid}
        setFlashcardDefinition={setFlashcardDefinition}
      />
      <FlashcardModal
        isModalOpen={isModalOpen === "VERB_FLASHCARD"}
        flashcardDefinition={flashcardDefinition}
        setIsModalOpen={setIsModalOpen}
        setFlashcardDefinition={setFlashcardDefinition}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbar.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        // message={snackbar.message}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message} <br />
          <a
            style={{ color: "#fff" }}
            href="https://chatgpt.com/"
            target="_blank"
          >
            Paste it into ChatGPT
          </a>
        </Alert>
      </Snackbar>
      {/* <label htmlFor="notes"></label> */}
      {/* <textarea name="notes" id="notes" cols="30" rows="10">
        {video.notes}
      </textarea> */}
    </Container>
  );
};

export default Video;

function SubtitleLines(props) {
  const {
    config,
    setConfig,
    video,
    modifierKey,
    setModifierKey,
    userUuid,
    videoUuid,
    flashcardWords,
    setFlashcardWords,
    setSelectedWords,
    selectedWords,
    url,
    isMobile,
    setSnackbar,
    flashcardDefinition,
    setFlashcardDefinition,
    setIsModalOpen,
  } = props;

  const { subtitles, lang } = video;
  const { playedSeconds: _playedSeconds, played } = config;

  if (!_playedSeconds || !subtitles) {
    return null;
  }

  const playedSeconds = _playedSeconds + 0.47;

  const renderedText = [];
  const subTextArr = [];

  for (let i = 0; i < subtitles.length; i++) {
    const subObj = subtitles[i];

    const subStart = parseFloat(subObj.start);
    const subDur = parseFloat(subObj.dur);

    if (subStart <= playedSeconds && subStart + subDur >= playedSeconds) {
      const subObjIndex = i;
      subTextArr.push({ text: subObj.text, subObjIndex });
      renderedText.push(
        <p className="subtitle-line" key={subObj.text}>
          <SingleLine
            subTextArr={subTextArr}
            setSelectedWords={setSelectedWords}
            selectedWords={selectedWords}
            config={config}
            setConfig={setConfig}
            text={subObj.text}
            lang={lang}
            modifierKey={modifierKey}
            setModifierKey={setModifierKey}
            userUuid={userUuid}
            videoUuid={videoUuid}
            flashcardWords={flashcardWords}
            setFlashcardWords={setFlashcardWords}
            subObjIndex={subObjIndex}
            subtitles={subtitles}
            url={url}
            playedSeconds={playedSeconds}
            played={played}
            isMobile={isMobile}
            setSnackbar={setSnackbar}
            flashcardDefinition={flashcardDefinition}
            setFlashcardDefinition={setFlashcardDefinition}
            setIsModalOpen={setIsModalOpen}
          />
        </p>
      );
    }
  }

  return renderedText;
}

function SingleLine(props) {
  const {
    text,
    lang,
    config,
    setConfig,
    modifierKey,
    setModifierKey,
    userUuid,
    videoUuid,
    flashcardWords,
    setSelectedWords,
    selectedWords,
    setFlashcardWords,
    subtitles,
    subObjIndex,
    url,
    playedSeconds,
    played,
    subTextArr,
    isMobile,
    setFlashcardDefinition,
    setIsModalOpen,
    flashcardDefinition,
    setSnackbar,
  } = props;

  const clickHandler = (wordObj) => (e) => {
    e.preventDefault();

    setTimeout(() => {
      setModifierKey(null);
    }, 100);

    // * create flashcard with word, lang, start and end and as well as video id
    if (modifierKey === "w") {
      // * Do flashcard API call
      const endObj = subtitles[subObjIndex + 1] || subtitles[subObjIndex];

      const originalText = (
        (subtitles[subObjIndex - 1]?.text || "") +
        " " +
        text +
        " " +
        (subtitles[subObjIndex + 1]?.text || "")
      ).trim();
      const ratio = played / playedSeconds;
      const startAboveText = subtitles[subObjIndex - 2]
        ? ratio * subtitles[subObjIndex - 2].start
        : "";
      const aboveText = subtitles[subObjIndex - 2]?.text || "";

      const belowText = subtitles[subObjIndex + 2]?.text || "";
      const endBelowText = subtitles[subObjIndex + 2]
        ? ratio *
          (parseFloat(subtitles[subObjIndex + 2].start) +
            parseFloat(subtitles[subObjIndex + 2].dur))
        : "";

      const start = subtitles[subObjIndex - 1]
        ? ratio * subtitles[subObjIndex - 1].start
        : ratio * subtitles[subObjIndex].start;

      const wordOrPhrase = selectedWords.length ? selectedWords : [wordObj];
      return axios
        .post(API_ROOT + "flashcards/", {
          userUuid,
          videoUuid,
          wordOrPhrase: selectedWords.length ? selectedWords : [wordObj],
          start,
          end: ratio * (parseFloat(endObj.start) + parseFloat(endObj.dur)),
          startAboveText,
          aboveText,
          belowText,
          endBelowText,
          lang,
          url,
          // * Will add more lines
          originalText,
        })
        .then((data) => {
          setFlashcardWords([...flashcardWords, wordOrPhrase]);
          setIsModalOpen("FLASHCARD");
          setFlashcardDefinition(data.data.flashcard.notes);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (modifierKey === "v") {
      // * Do flashcard API call

      const originalText = (
        (subtitles[subObjIndex - 1]?.text || "") +
        " " +
        text +
        " " +
        (subtitles[subObjIndex + 1]?.text || "")
      ).trim();

      return axios
        .post(API_ROOT + "catalog-videos/verb", {
          userUuid,

          verb: wordObj.word,
          context: originalText,
        })
        .then(({ data }) => {
          setIsModalOpen("VERB_FLASHCARD");
          setFlashcardDefinition(data.flashcardText);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    window.open(
      `https://www.wordreference.com/${lang}en/${wordObj.normalizedWord}`,
      "WordReference",
      "top=100,left=200,menubar=0,resizable=1,width=900,height=800"
    );
  };
  return cleanAndSplit(text).map((word, idx) => {
    const normalizedWord = normalize(word);
    const wordIndex = idx;

    function handleWordSelection() {
      const firstWord = selectedWords[0];
      if (
        !firstWord ||
        calculateWordScore(firstWord) >
          calculateWordScore({
            subObjIndex,
            wordIndex: idx,
          })
      ) {
        return setSelectedWords([
          {
            subObjIndex,
            wordIndex,
            normalizedWord,
            word,
          },
        ]);
      } else {
        const firstWordScore = calculateWordScore(firstWord);
        const curWordScore = calculateWordScore({
          subObjIndex,
          wordIndex: idx,
        });
        const newSelectedWords = subTextArr.reduce((acc, line) => {
          cleanAndSplit(line.text).forEach((innerWord, innerIdx) => {
            const innnerNormalizedWord = normalize(innerWord);
            const innerWordScore = calculateWordScore({
              subObjIndex: line.subObjIndex,
              wordIndex: innerIdx,
            });
            if (
              innerWordScore >= firstWordScore &&
              innerWordScore <= curWordScore
            )
              acc.push({
                subObjIndex: line.subObjIndex,
                wordIndex: innerIdx,
                normalizedWord: innnerNormalizedWord,
                word: innerWord,
              });
          });
          return acc;
        }, []);
        setSelectedWords(newSelectedWords);
      }
    }

    return (
      <>
        <span
          key={idx + word}
          className={classNames({ isHighlightOnHover: modifierKey === "w" })}
          style={{
            color: handleColor(
              normalizedWord,
              flashcardWords,
              selectedWords,
              subObjIndex,
              wordIndex
            ),
          }}
          onClick={clickHandler({
            normalizedWord,
            word,
            subObjIndex,
            wordIndex,
          })}
          onMouseLeave={(e) => {
            if (modifierKey === "w") {
              handleWordSelection();
            }
          }}
          onMouseOver={(e) => {
            document.activeElement.blur();
            if (modifierKey === "w") {
              handleWordSelection();
            }
            if (config.playing && !isMobile.any) {
              setConfig({
                ...config,
                playing: false,
              });
            }
          }}
        >
          {word}
        </span>{" "}
      </>
    );
  });
}

function normalize(word) {
  return word.replace(/(\(|\)|,|\.|\:|\!)/g, "");
}

export function cleanAndSplit(line) {
  return line.replace("\n", " ").replace(/\s/g, " ").split(" ");
}

function calculateWordScore({ subObjIndex, wordIndex }) {
  return subObjIndex * 100 + wordIndex;
}

function handleColor(
  normalizedWord,
  flashcardWords,
  selectedWords,
  subObjIndex,
  wordIndex
) {
  if (
    selectedWords.find((sWord) => {
      return (
        sWord.subObjIndex === subObjIndex &&
        sWord.normalizedWord === normalizedWord &&
        sWord.wordIndex === wordIndex
      );
    })
  ) {
    return "lightblue";
  }
  if (
    normalizedWord &&
    normalizedWord.length > 0 &&
    flashcardWords.some((arr) => {
      return arr.find((sWord) => {
        return (
          sWord.subObjIndex === subObjIndex &&
          sWord.normalizedWord === normalizedWord &&
          sWord.wordIndex === wordIndex
        );
      });
    })
  ) {
    return "var(--link)";
  }
  return "var(--color)";
}

const Container = styled.div`
  margin: 0 auto;
  width: fit-content;

  .responsive-container {
    position: relative;
    width: fit-content;
    margin-bottom: 5px;
    overflow: hidden;
    border-radius: 3px;

    .player-container {
      width: fit-content;
      ${(props) =>
        !props.controls &&
        css`
          pointer-events: none;
        `}
    }

    .click-handler {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: backdrop-filter 0.3s ease;
      cursor: pointer;

      ${(props) => {
        if (props.screenEffect === "none") {
          return css`
            transition: unset;
            backdrop-filter: unset;
          `;
        } else {
          // * "opacity"
          return css`
            transition: backdrop-filter 0.3s ease;

            backdrop-filter: grayscale(
                ${(props) => (props.playing ? "0%" : "100%")}
              )
              blur(${(props) => (props.playing ? "0px" : "5px")});
          `;
        }
      }};
    }
  }

  [data-reach-tooltip] {
    background: var(--accent-bg) !important;
    border-radius: 3px;
    font-size: 30px;
  }

  input[type="range"] {
    position: relative;
    top: 4px;
    @media screen and (max-width: 500px) {
      width: 100px;
    }
  }
  input[type="range"]:first-of-type {
    margin-right: 8px;
  }

  .range-container {
    display: flex;
  }

  .menu-btn {
    width: max-content;
  }

  .subtitle {
    text-align: center;
    width: 100%;
    margin: 0 auto;

    .subtitle-line {
      max-width: 800px;
      max-width: 90%;
      margin: 0 auto;

      @media screen and (max-width: 500px) {
        display: inline;
      }

      span {
        text-decoration: none;
        color: var(--color);
        font-size: 1.6rem;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &.isHighlightOnHover:hover {
          color: lightblue !important;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    button {
      outline: none;
    }
    button:hover {
      text-decoration: none;
    }
    button:focus-within,
    button:focus {
      text-decoration: none;
    }
  }
`;
