import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";

import Header from "../../components/Header";
import LoadingSpinner from "../../components/LoadingSpinner";
import { UserContext } from "../../components/Layout";
import { API_ROOT } from "../../constants";

const Container = styled.div`
  max-width: 650px;
  margin: 0 auto;

  a {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .link-and-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #translation {
    width: 100%;
    min-height: 300px;
    font-family: inherit;
    font-size: 18px;
    padding: 8px;
    box-sizing: border-box;
  }

  .button-container {
    display: flex;
    justify-content: space-between;

    .nav-button {
      width: max-content;
    }
  }
`;

// * Not in use
const LANGUAGE_TYPE = "NATIVE";

function NewsTranslate(props) {
  // * Not in use
  const { outlet } = props;
  const { search } = props.location;
  const [translation, setTranslation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState("");

  const user = useContext(UserContext);

  useEffect(() => {
    // * Data call
    const qs = queryString.parse(search);
    if (user.uuid) {
      async function fetchTranslation() {
        const { data } = await axios(
          API_ROOT + "translations/" + user.uuid + "/" + qs.uuid
        );

        setTranslation(data.translation);
        setIsLoading(false);
      }

      fetchTranslation();
    }
  }, [search, user]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "c") {
        setKey(event.key);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "c") {
        setKey("");
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (isLoading || !translation) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header>{wrapTextInDictionLinks(translation?.title, key, setKey)}</Header>
      <div className="link-and-actions">
        <a target="_blank" href={translation?.href}>
          Article Link
        </a>

        <div>
          <Menu>
            <MenuButton className="menu-btn">
              Actions <span aria-hidden>▾</span>
            </MenuButton>

            <MenuList>
              <MenuItem onSelect={() => console.log("FEEDBACK")}>
                File Bug or Feedback
              </MenuItem>
              <MenuItem onSelect={() => console.log("SHORTCUTS")}>
                View Keyboard Shortcuts
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <Body
        content={translation?.body}
        translation={translation.nativeTranslation}
        uuid={translation.uuid}
        user={user}
        // * This is because you cannot use the prop key, since it is for arrays
        modifierKey={key}
        setKey={setKey}
        setTranslation={setTranslation}
      />
    </Container>
  );
}

const Body = (props) => {
  const {
    content,
    modifierKey: key,
    translation,
    user,
    uuid,
    setKey,
    setTranslation,
  } = props;

  const [placement, setPlacement] = useState(0);
  const [transText, setTransText] = useState(translation[placement]);
  const [renderedContent, setRenderedContent] = useState([]);

  useEffect(() => {
    const newRenderedContent = [];
    const el = content[placement];
    if (el.tagName === "P") {
      newRenderedContent.push(
        <p key={placement}>{wrapTextInDictionLinks(el.text, key, setKey)}</p>
      );
    } else if (el.tagName === "BLOCKQUOTE") {
      newRenderedContent.push(
        <blockquote key={placement}>
          {wrapTextInDictionLinks(el.text, key, setKey)}
        </blockquote>
      );
    } else if (el.tagName === "H2") {
      newRenderedContent.push(
        <h2 key={placement}>{wrapTextInDictionLinks(el.text, key, setKey)}</h2>
      );
    }

    setRenderedContent(newRenderedContent);
  }, [content, key, placement, translation]);

  useEffect(() => {
    setTransText(translation[placement]);
  }, [placement, translation]);

  return (
    <div>
      {renderedContent}

      <label htmlFor="translation">
        <strong>Translation</strong>
      </label>
      <textarea
        value={transText || ""}
        name="translation"
        id="translation"
        onChange={(e) => {
          const { value } = e.target;
          setTransText(value);
        }}
      />
      <div className="button-container">
        <button
          className="nav-button"
          disabled={placement < 1}
          onClick={async (e) => {
            e.preventDefault();

            const translationArr = [...translation];
            translationArr[placement] = transText;
            try {
              const { data } = await axios.post(
                API_ROOT + "translations/" + user.uuid + "/" + uuid,
                {
                  translationArr,
                  LANGUAGE_TYPE,
                }
              );

              setTransText(translation[placement - 1] || "");
              setTranslation(data.translation);
              setPlacement(placement - 1);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {"<-"} Back
        </button>
        <button
          className="nav-button"
          onClick={async (e) => {
            e.preventDefault();

            const translationArr = [...translation];
            translationArr[placement] = transText;
            try {
              const { data } = await axios.post(
                API_ROOT + "translations/" + user.uuid + "/" + uuid,
                {
                  translationArr,
                  LANGUAGE_TYPE,
                }
              );

              setTransText(translation[placement + 1] || "");
              setTranslation(data.translation);
              setPlacement(placement + 1);
            } catch (err) {
              console.error(err);
            }
          }}
          disabled={placement >= content?.length - 1}
        >
          Next {"->"}
        </button>
      </div>
    </div>
  );
};

function wrapTextInDictionLinks(text, key, setKey) {
  return text
    .replace(/\n/, " ")
    .split(" ")
    .map((word, idx) => (
      <span key={word + idx}>
        <a
          onClick={(e) => {
            e.preventDefault();
            setTimeout(() => {
              setKey(null);
            }, 100);

            const lang = "fr";
            const normalizedWord = word.replace(/(«|»|\,|\.|\:)/g, "");
            if (key === "c") {
              if (lang === "de") {
                window.open(
                  `https://conjugator.reverso.net/conjugation-german-verb-${normalizedWord}.html`
                );
              }
              return window.open(
                `https://www.wordreference.com/conj/${lang}verbs.aspx?v=${normalizedWord}`
              );
            }
            window.open(
              `https://www.wordreference.com/${lang}en/${normalizedWord}`
            );
          }}
        >
          {word}
        </a>{" "}
      </span>
    ));
}

export default NewsTranslate;
