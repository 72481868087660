import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import { API_ROOT } from "../../constants";
import styled from "styled-components";
import removeAccent from "remove-accents";
import { UserContext } from "../../components/Layout";
import FlashcardModal from "../../components/Modals/FlashcardModal";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;

  p {
    line-height: 26px;
    margin-bottom: 32px;
    font-size: 22px;
    cursor: pointer;
  }
`;

const StyledSpan = styled.span`
  color: ${(props) =>
    props.isSelected
      ? "var(--link)"
      : props.isDefined
      ? "magenta"
      : "var(--color)"};
  display: inline;
  &:hover {
    text-decoration: underline;
    color: ${(props) => {
      return props.isWPressed || props.isSelected
        ? "var(--link)"
        : props.isDefined
        ? "magenta"
        : "var(--color)";
    }};
  }
`;

const url = "https://www.gutenberg.org/cache/epub/4771/pg4771.txt";

function BookDetails(props) {
  const { lang, uuid } = props;
  const [data, setData] = useState([]);
  const [words, setWords] = useState([]);
  const [modifierKey, setModifierKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phraseDefinition, setPhraseDefinition] = useState("");
  const [definitions, setDefinitions] = useState([]);
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user.uuid) {
      async function fetchData() {
        const { data } = await axios.get(API_ROOT + "books/123");
        const splitData = data.replace(/\r/g, " ").split(/\n/);
        let paragraphData = [];
        let str = "";
        splitData.forEach((line) => {
          if (line.length === 1) {
            if (str) {
              paragraphData.push(str.trim());
            }
            str = "";
          } else {
            str += ` ${line}`;
          }
        });
        setData(paragraphData);

        const { data: defData } = await axios.get(
          API_ROOT + "books/definitions/" + user.uuid + "?url=" + url
        );

        setDefinitions(defData.bookDefinitionList);
        setIsLoading(false);
      }

      try {
        fetchData();
      } catch (err) {
        console.error(err.message);
      }

      function handleKeyDown(e) {
        if (["w", "v"].includes(e.key)) {
          setModifierKey(e.key);
        }
      }
      function handleKeyUp(e) {
        if (["w", "v"].includes(e.key)) {
          setModifierKey(null);
          setWords([]);
        }
      }

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [user]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header>BookDetails</Header>
      <div id="text-container">
        {data.map((line, idx) => {
          return (
            <ParagraphContainer
              key={idx}
              line={line}
              idx={idx}
              modifierKey={modifierKey}
              words={words}
              setWords={setWords}
              lang={lang}
              userUuid={user.uuid}
              setPhraseDefinition={setPhraseDefinition}
              setIsModalOpen={setIsModalOpen}
              definitions={definitions}
              setDefinitions={setDefinitions}
            />
          );
        })}
      </div>
      <FlashcardModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        flashcardDefinition={phraseDefinition}
        setFlashcardDefinition={setPhraseDefinition}
        setDefinitions={setDefinitions}
      />
    </Container>
  );
}

const ParagraphContainer = (props) => {
  const {
    line,
    modifierKey,
    words,
    setWords,
    lang,
    userUuid,
    setIsModalOpen,
    setPhraseDefinition,
    definitions,
    setDefinitions,
    idx,
  } = props;
  const [isMouseOver, setIsMouseOver] = useState(false);

  const splitLine = line.split(" ").filter((word) => word.length > 0);

  if (!isMouseOver) {
    return (
      <p
        onMouseOver={(e) => {
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
      >
        {splitLine.map((word, idx) => {
          const wordOrPhraseList = definitions
            .map((obj) => obj.wordOrPhrase)
            .flat();
          const isDefined = wordOrPhraseList.find(
            (obj) => obj.word === word && obj.idx === idx
          );
          return (
            <span
              style={{ color: isDefined ? "magenta" : "inherit" }}
              key={`${word}-${idx}`}
            >
              {word}{" "}
            </span>
          );
        })}
      </p>
    );
  }

  return (
    <p
      onMouseOver={(e) => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
        setWords([]);
      }}
    >
      {splitLine.map((word, idx) => {
        const wordOrPhraseList = definitions
          .map((obj) => obj.wordOrPhrase)
          .flat();
        const isDefined = wordOrPhraseList.find(
          (obj) => obj.word === word && obj.idx === idx
        );

        return (
          <StyledSpan
            key={`${word}-${idx}`}
            isWPressed={modifierKey === "w"}
            isSelected={words.some(
              (obj) => obj.word === word && obj.idx === idx && obj.line === line
            )}
            isDefined={isDefined}
            onClick={async (e) => {
              if (modifierKey === "w") {
                const { data } = await axios.post(
                  API_ROOT + "books/definition",
                  {
                    userUuid,
                    url,
                    lang,
                    originalText: splitLine.join(" "),
                    wordOrPhrase: words,
                  }
                );
                setPhraseDefinition(data.bookDefinition.definition);
                setDefinitions([...definitions, data.bookDefinition]);

                return setIsModalOpen(true);
              }
              window.open(
                `https://www.wordreference.com/${lang}en/${removeAccent(word)}`,
                "WordReference",
                "top=100,left=200,menubar=0,resizable=1,width=900,height=800"
              );
            }}
            onMouseOver={() => {
              if (modifierKey === "w") {
                // * If first word is after newword, switch to new word
                const firstWordObj = words[0] || { idx: -1 };

                const lastWordObj = words[words.length - 1];
                const nextWordObj = { line, word, idx };

                if (firstWordObj?.idx > nextWordObj?.idx) {
                  return setWords([nextWordObj]);
                }
                if (firstWordObj.idx + 1 !== nextWordObj.idx) {
                  const nextWords = splitLine
                    .map((_word, _idx) => ({ word: _word, idx: _idx, line }))
                    .slice(firstWordObj.idx, nextWordObj.idx);

                  return setWords(nextWords);
                }

                setWords((prevWords) => [...prevWords, nextWordObj]);
              }
            }}
            onMouseLeave={() => {
              if (modifierKey === "w") {
                if (
                  !words.some(
                    (obj) =>
                      obj.line === line && obj.word === word && obj.idx === idx
                  )
                ) {
                  setWords((prevWords) => [...prevWords, { line, word, idx }]);
                }
              }
            }}
          >
            {word}{" "}
          </StyledSpan>
        );
      })}
    </p>
  );
};

export default BookDetails;
