import axios from "axios";
import { navigate } from "gatsby";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../../components/Layout";
import { API_ROOT } from "../../constants";
import adminEmails from "../../util/adminEmails";

const StyledVideoMain = styled.main`
  box-sizing: border-box;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    label {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      font-size: 16px;
      max-width: 74vw;

      padding: 8px 10px;
      border: 1px solid #222;
      border-radius: 3px;
    }

    #public-video-checkbox {
      padding-top: 20px;
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;

      input {
        height: 22px;
        width: 22px;
      }
    }

    button {
      margin-top: 10px;
      border-radius: 3px;
      padding: 8px 10px;
      width: 80px;
      display: flex;
      justify-content: center;
      background: #222;
      font-size: 18px;
      color: #fff;
      border: none;
      cursor: pointer;
    }
  }
`;

const initialState = {
  channelId: "",
  lang: "",
};

const AddChannel = (props) => {
  const user = useContext(UserContext);
  const inputRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [formState, setFormState] = useState(initialState);

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }

  useEffect(() => {
    inputRef?.current.focus();
  }, []);

  return (
    <StyledVideoMain>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsDisabled(true);
          const { channelId, lang } = formState;
          if (!channelId || !lang) {
            return;
          }

          const resp = await axios.post(API_ROOT + "catalog-videos", {
            userUuid: user.uuid,
            channelId,
            lang,
            email: user.email,
          });
          console.log("🚀 ~ onSubmit={ ~ resp:", resp);

          //   navigate("/app/video/" + resp.data.video.uuid);
        }}
      >
        <label htmlFor="channelId">Add a YouTube Channel ID:</label>
        <input
          type="text"
          name="channelId"
          id="channelId"
          onChange={handleChange}
          ref={inputRef}
        />
        <br />
        <label htmlFor="lang">Track Language:</label>
        <input type="text" name="lang" id="lang" onChange={handleChange} />

        <button
          disabled={isDisabled || !adminEmails.includes(user.email)}
          type="submit"
        >
          Add
        </button>
      </form>
    </StyledVideoMain>
  );
};

export default AddChannel;
