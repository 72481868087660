import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Header from "../../../components/Header";
import { FlashcardContext, UserContext } from "../../../components/Layout";
import Notification from "../../../components/Notification";
import { API_ROOT } from "../../../constants";
import LoadingSpinner from "../../../components/LoadingSpinner";
import useDarkMode from "use-dark-mode";
import getSelectedLang from "../../../util/getSelectedLang";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  li.flashcard-item {
    margin-bottom: 8px;
    a {
      font-size: 22px;
      color: var(--link);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

function CatalogFlashcards(props) {
  const user = useContext(UserContext);
  // ! The below global state is used by the older flashcard code
  const { flashcards, setFlashcards } = useContext(FlashcardContext);
  const [isLoading, setIsLoading] = useState(true);
  const darkMode = useDarkMode();
  const selectedLang = getSelectedLang();

  useEffect(() => {
    if (user.uuid) {
      axios
        .get(
          API_ROOT + "catalog-flashcards/" + user.uuid + "?lang=" + selectedLang
        )
        .then(({ data }) => {
          setFlashcards(data.flashcards);
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [user.uuid]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Flashcards</title>
      </Helmet>

      <Container isDarkMode={darkMode.value}>
        <Header>Flashcards</Header>
        <Notification>
          Flashcards are added from transcripts of{" "}
          <Link to="/app/catalog">Catalog Videos.</Link>
          <br />
          <br />
          Sometimes <strong>cookies alter the starting point</strong> of the
          flashcard video. To solve this, open ALL in incognito mode or just
          click the play button twice. Or clear your cookies.
        </Notification>
        <ul>
          {flashcards.map((fc) => (
            <li key={fc.uuid} className="flashcard-item">
              <Link to={`/app/catalog/flashcards/${fc.uuid}`}>
                {fc.wordOrPhrase.map((w) => w.word).join(" ")}
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
}

export default CatalogFlashcards;
