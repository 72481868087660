export default function setRecentVideo(newVideo) {
  let videos =
    JSON.parse(localStorage.getItem("recent_videos", newVideo)) || [];

  if (videos.filter((vid) => vid.uuid === newVideo.uuid).length === 0) {
    console.log("here");
    videos.unshift(newVideo);
    if (videos.length > 6) {
      videos.pop();
    }
  } else {
    videos = videos.filter((vid) => vid.uuid !== newVideo.uuid);

    videos.unshift(newVideo);
  }
  localStorage.setItem("recent_videos", JSON.stringify(videos));
}
